import './style.css'

export default function HeroBanner( {children} ) {
  return (
    <section className='section-hero flex items-center'>
      <div 
        className="container px-4 flex flex-col lg:flex-row items-center justify-center lg:justify-between h-full">
        {children}
      </div>
    </section>
  )
}