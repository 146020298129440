import EmbedVideo from "../../components/EmbedVideo"

export default function Merchadising( {children} ) {
  return (
    <>
      <section className="section-merchadising bg-blue-950 py-10">
        <div className="container px-4 text-white text-center">
          <h3 className="font-bold text-lg md:text-3xl">
            Maior Pack de softwares profissionais <span className="text-[#00FF00]">(São várias Ferramentas e Bônus)</span> Exclusivos <span className="text-[#00FF00]">(Com gerador de licenças para revenda!)</span>
          </h3>
          <EmbedVideo />
          <h3 className="font-bold text-xl md:text-2xl">
            Todas 100% funcionais para windows, <span className="text-[#00FF00] underline">com video aulas de como instalar e usar</span>
          </h3>
          <h2 className="font-bold text-3xl md:text-5xl my-5">
            <span className="text-[#00FF00]">Acesso vitalício</span> e sem custos adicionais
          </h2>

          <p className="text-lg font-bold">Impulsione seu negócio com esse arsenal de ferramentas</p>

          <p className="text-lg my-5">
            Você receberá todo o material e a plataforma com todas as ferramentas <strong>direto no seu email</strong>
          </p>

          <p className="text-xl font-bold">
            APROVEITE 👉 <span className="text-[#00FF00] underline">ULTIMAS UNIDADES COM ACESSO VITALÍCIO</span>
          </p>

          <a href="https://pay.kiwify.com.br/iNgnr5D" target="_blank" 
            className="bg-yellow-400 border border-black rounded-full px-5 md:px-20 py-4 flex items-center w-max mx-auto my-8 text-3xl md:text-5xl font-bold leading-none"
          >
            ADQUIRIR AGORA
          </a>

          <p className="text-2xl md:text-3xl">De <span className="text-red-600 line-through">R$200</span> por apenas</p>

          <p className="text-9xl font-bold text-[#00FF00] my-5">R$37</p>

        </div>
      </section>
    </>
  )
}