import mockup_image from '../../assets/mockup-hero.png'

export default function Banner() {
  return (
    <>
      <div className="content text-center lg:text-start lg:flex-1 text-white">
        <h1 className='text-4xl sm:text-5xl lg:text-7xl font-bold'>
          PACK DE <br /> 
          <span className="text-[#00FF00]">
            FERRAMENTAS PREMIUM.
          </span>
        </h1>
        <h2 className='text-2xl sm:text-3xl lg:text-4xl bg-[#00FF00] w-max mx-auto lg:mx-0 my-5 font-bold p-1 rounded text-black'>
          +50 FERRAMENTAS.
        </h2>
        <p className='text-1xl sm:text-2xl font-medium'>
          ACESSO 
          <span className='text-[#00FF00]'>VITALÍCIO</span>.
        </p>
        <p className='text-1xl sm:text-2xl font-medium'>
          GERADOR DE LICENÇAS PARA 
          <span className='text-[#00FF00]'>REVENDA</span>.
        </p>
        <p className='text-1xl sm:text-2xl font-medium'>
          PAGAMENTO 
          <span className='text-[#00FF00]'>ÚNICO!</span>
        </p>
        <p className='text-1xl sm:text-2xl text-[#00FF00] font-medium'>
          SOFTWARE, SAAS E APP.
        </p>
        <p className='text-1xl sm:text-2xl font-medium'>
          VERSÕES PARA 
          <span className='text-[#00FF00]'>PC</span> E <span className='text-[#00FF00]'>CELULAR.</span>
        </p>
      </div>
      <img srcSet={mockup_image} alt="Mockup Ferramentas" 
        className='max-w-full lg:flex-1'
      />
    </>
  )
}