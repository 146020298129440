export default function Softwares( {children} ) {
  return (
    <section className="section-softwares bg-[#000226] text-white py-6">
      <div className="container px-4 py-10">
        <h2 className="font-bold text-lg md:text-2xl text-center">
          Dá uma olhada nas ferramenta abaixo 👇👇
        </h2>
      </div>
      <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
        {children}
      </div>

      <div className="container px-4">
        <h2 className="font-bold text-lg md:text-2xl text-center mb-5">
          Comprando 1 leva as 50 ferramentas para uso e para revenda!
        </h2>
        <a href="https://pay.kiwify.com.br/iNgnr5D" target="_blank" 
            className="bg-yellow-400 border border-black rounded-full px-5 md:px-20 py-4 flex items-center w-max mx-auto text-3xl md:text-5xl font-bold leading-none"
          >
            ADQUIRIR AGORA
        </a>
      </div>
    </section>
  )
}