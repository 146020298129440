import videoMerch from '../../assets/video.mp4';

export default function EmbedVideo() {
  return (
    <>
      <video autoPlay controls width="340" height="526" className='mx-auto my-5'>
        <source src={videoMerch} type="video/mp4" />
      </video>
    </>
  );
}
