export default function Card( props ) {
  return (
    <>
      <div className="flex flex-col gap-2 text-white mb-6">
        <img srcSet={props.img} alt="" className="max-w-full aspect-video" />
        <div className="text-center text-base md:text-2xl">
          <p className="">{props.name}</p>
          <p className="text-[#00FF00] text-base">&#40;{props.type}&#41;	</p>
        </div>
      </div>
    </>
  )
}