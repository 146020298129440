import { useState } from 'react'
import Main from './sections/Main'
import HeroBanner from './sections/HeroBanner'
import Banner from './components/Banner'
import Merchadising from './sections/Merchadising'
import Softwares from './sections/Softwares'
import Card from './components/Card'
import './App.css'

import wa_sender_1 from './assets/image_1.webp'
import wa_sender_2 from './assets/image_2.webp'
import ultra_mailer from './assets/image_3.webp'
import anw_robo from './assets/image_4.webp'
import socinator from './assets/image_6.webp'
import tubeviews from './assets/image_7.webp'
import group_marketing from './assets/image_8.webp'
import advanced_generator from './assets/image_9.webp'
import wbot from './assets/image_10.webp'
import autochat_instagram from './assets/image_11.webp'
import message_generator from './assets/image_12.webp'
import g_bussiness_map from './assets/image_13.webp'
import insta_extractor from './assets/image_14.webp'
import wa_sender_3 from './assets/image_15.webp'
import botwpp_mobile from './assets/image_16.webp'
import robot_wpp from './assets/image_17.webp'
import quick_3d from './assets/image_18.webp'
import leads from './assets/image_19.webp'
import extrator_contact_group from './assets/image_20.webp'
import tiktok_bot from './assets/image_21.webp'
import whats_leader from './assets/image_22.webp'
import insta_contact_extractor from './assets/image_23.webp'
import sms_auto_reply from './assets/image_24.webp'
import botmaster from './assets/image_25.webp'
import robot_facebook from './assets/image_26.webp'
import extractor_contact_maps from './assets/image_27.webp'
import instagram_lead from './assets/image_28.webp'
import crm_kanban from './assets/image_29.webp'
import sms_massive from './assets/image_30.webp'
import vidscribe from './assets/image_31.webp'
import tuberrank from './assets/image_32.webp'
import insta_turbo from './assets/image_33.webp'

function App() {

  const softwareArray = [
    {
      imageLink: robot_facebook,
      name: 'ROBO POSTADOR AUTOMÁTICO EM GRUPOS DO FACEBOOK',
      type: 'EXTENSÃO PARA FACEBOOK',
    },
    {
      imageLink: advanced_generator,
      name: 'ADVANCED GERADOR DE NÚMEROS PARA WHATSAPP',
      type: 'SOFTWARE',
    },
    {
      imageLink: crm_kanban,
      name: 'SaaS CRM KANBAN WHATSAPP',
      type: 'Multi-Contas/Kanban/Chatbot/Envios',
    },
    {
      imageLink: wa_sender_1,
      name: '(Novo) WA SENDER 3.2 + CHAVE DE LICENÇAS',
      type: 'SOFTWARE DE AUTOMAÇÃO PARA WHATSAPP',
    },
    {
      imageLink: wa_sender_2,
      name: 'WA SENDER 2024 VERSÃO 3.1.0',
      type: 'SOFTWARE PARA WHATSAPP',
    },
    {
      imageLink: wa_sender_3,
      name: 'WA SENDER 2023 + GERADOR DE LICENÇAS',
      type: 'SOFTWARE PARA WHATSAPP',
    },
    {
      imageLink: whats_leader,
      name: 'WHATSLEADER - AQUECEDOR DE NÚMEROS',
      type: 'SOFTWARE PARA WHATSAPP',
    },
    {
      imageLink: wbot,
      name: 'AUTO RESPONDE ROBÔ CHATBOT',
      type: 'APP PARA WHATSAPP',
    },
    {
      imageLink: robot_wpp,
      name: 'ROBO WHATSSAP + GERADOR DE LICENÇAS',
      type: 'SOFTWARE PARA WHATSAPP',
    },
    {
      imageLink: botmaster,
      name: 'BOTMASTER + PAINEL LICENÇAS',
      type: 'SOFTWARE PARA WHATSAPP',
    },
    {
      imageLink: extractor_contact_maps,
      name: 'EXTRATOR DE CONTATOS DO GOOGLE MAPS',
      type: 'SOFTWARE PARA GOOGLE',
    },
    {
      imageLink: extrator_contact_group,
      name: 'EXTRATOR DE GRUPOS DO WHATSAPP PC ANDROID',
      type: 'EXTENSÃO PARA WHATSAPP',
    },
    {
      imageLink: botwpp_mobile,
      name: 'CHATBOT WHATSAPP VERSÃO CELULAR',
      type: 'APP PARA WHATSAPP',
    },
    {
      imageLink: anw_robo,
      name: 'ANW ROBÔ DIVULGADOR EM GRUPOS',
      type: 'SOFTWARE PARA WHATSAPP',
    },
    {
      imageLink: group_marketing,
      name: 'DIVULGADOR DE GRUPO',
      type: 'EXTENSÃO PARA WHATSAPP',
    },
    {
      imageLink: leads,
      name: 'MAIS DE 700 MIL LEADS DO MARKETING DIGITAL',
      type: 'CONTATOS PARA VENDAS',
    },
    {
      imageLink: instagram_lead,
      name: 'LEAD ULTIMATE EXTRATOR DE CONTATOS',
      type: 'SOFTWARE PARA INSTAGRAM',
    },
    {
      imageLink: autochat_instagram,
      name: 'AUTO RESPONDE CHATBOT',
      type: 'APP PARA INSTAGRAM',
    },
    {
      imageLink: insta_turbo,
      name: 'IG TURBO + GERADOR DE LICENÇAS',
      type: 'SOFTWARE PARA INSTAGRAM',
    },
    {
      imageLink: insta_extractor,
      name: 'INSTA EXTRACTOR + GERADOR DE LICENÇAS',
      type: 'SOFTWARE PARA INSTAGRAM',
    },
    {
      imageLink: insta_contact_extractor,
      name: 'EXTRATOR DE CONTATO INSTAGRAM',
      type: 'EXTENSÃO PARA INSTAGRAM',
    },
    {
      imageLink: ultra_mailer,
      name: 'ULTRAMAILER ENVIO EM MASSA DE EMAIL',
      type: 'SOFTWARE PARA EMAIL',
    },
    {
      imageLink: message_generator,
      name: 'CRIADOR DE PROVAS SOCIAIS',
      type: 'SOFTWARE PARA TELEGRAM',
    },
    {
      imageLink: g_bussiness_map,
      name: 'G-BUSINESS EXTRATOR DO GOOGLE',
      type: 'SOFTWARE PARA GOOGLE ',
    },
    {
      imageLink: socinator,
      name: 'SOCINATOR',
      type: 'SOFTWARE PARA GERENCIAR REDES SOCIAIS',
    },
    {
      imageLink: quick_3d,
      name: 'QUICK 3D - PC',
      type: 'SOFTWARE PARA IMAGEM',
    },
    {
      imageLink: sms_massive,
      name: 'ENVIO DE SMS EM MASSA',
      type: 'SOFTWARE PARA SMS',
    },
    {
      imageLink: sms_auto_reply,
      name: 'SMS AUTO REPLY - PREMIUM',
      type: 'SOFTWARE PARA SMS',
    },
    {
      imageLink: tiktok_bot,
      name: 'NINJATOK',
      type: 'SOFTWARE PARA TIKTOK',
    },
    {
      imageLink: tuberrank,
      name: 'TUBERANK JEET',
      type: 'SOFTWARE PARA YOUTUBE',
    },
    {
      imageLink: tubeviews,
      name: 'TubeViews',
      type: 'SOFTWARE PARA YOUTUBE',
    },
    {
      imageLink: vidscribe,
      name: 'VIDSCRIBE',
      type: 'SOFTWARE PARA YOUTUBE',
    },
  ];

  const renderCards = softwareArray.map((software) => 
    <Card name={software.name} type={software.type} img={software.imageLink} />
  )

  return (
    <>
      <Main>
        <HeroBanner>
          <Banner />
        </HeroBanner>
        <Merchadising />

        <Softwares>
          {renderCards}
        </Softwares>
      </Main>
    </>
  )
}

export default App
